<template>
  <div class="grid-view" v-if="personelID > 0">
    <el-col v-for="(item, index) in fileList" class="grid-item" :lg="24" :md="24" :sm="24" :xs="24">
      <el-card
          :body-style="{ display: 'flex', 'align-items': 'center', 'justify-content': 'center', padding: '10px' }"
          class="yanForm animated fadeInUp p-0 card-body-item"
          :class="{ 'warning': !item.fromServer, 'warningAnimation': animate }" style="height: 100%;">
        <el-col>
          <span>{{ item.title }}</span>
        </el-col>
        <el-col>
          <el-upload :id="item.id" action="" :auto-upload="false" :limit="1"
                     :file-list="item.fromServer === false ? item.file : []"
                     :on-change="(data) => handleFileChange(data, index,item.fromServer,item.updateID)"
                     :on-remove="() => handleRemove(index)"
                     :on-preview="() => handlePreview(index)" list-type="belge-card" accept="">
            <div slot="tip" class="el-upload__tip">
              <el-button v-if="item.file.length > 0 && !item.fromServer" :icon="'el-icon-upload'"
                         :loading="item.isUploading" size="mini" type="info"
                         @click="handleUploadFile(index)"
                         class="styled-upload-button mt-8">
                Dosyayı Yükle
              </el-button>
              <el-button v-if="item.file.length > 0 && item.fromServer" size="mini" :icon="'el-icon-download'"
                         type="primary" @click="handleDownloadFile(index)" class="styled-upload-button mt-8">
                Dosyayı İndir
              </el-button>
              <!--              <el-button v-if="item.file.length > 0 && item.fromServer" size="mini"-->
              <!--                                  :icon="'el-icon-edit'" type="warning" @click="handleDownloadFile(index)"-->
              <!--                                  class="styled-upload-button mt-8 ">Dosyayı Değiştir</el-button> -->
            </div>
            <el-button v-if="!item.fromServer && item.file.length === 0" class="styled-upload-button mt-8"
                       size="mini" type="default" :icon="'el-icon-folder-add'">
              Dosya Seç
            </el-button>
            <!--                        <el-button v-if="item.file.length > 0 && item.fromServer" size="mini" :icon="'el-icon-edit'"-->
            <!--                                   type="warning" @click="handleDownloadFile(index)" class="styled-upload-button mt-8 ">-->
            <!--                          Dosyayı Değiştir-->
            <!--                        </el-button>-->
            <div class="mini-action-icons">
              <el-tooltip v-if="item.file.length > 0 && item.fromServer" effect="dark"
                          content="Dosyayı Değiştir"
                          placement="bottom">
                <el-button class="update-btn" type="default" icon="el-icon-edit" circle size="mini"></el-button>
              </el-tooltip>
            </div>
            <div slot="tip" class="mini-action-icons">
              <div style="display: contents">
                <el-tooltip v-if="item.file.length > 0 && item.fromServer" effect="dark"
                            content="Dosyayı Sil" placement="bottom">
                  <el-button :loading="removeID === item.file[0].id" @click="fileRemove(index)" size="mini"
                             icon="el-icon-delete" circle></el-button>
                </el-tooltip>
              </div>
              <!--              <el-tooltip v-if="item.file.length > 0 && item.fromServer" class="item" effect="dark"-->
              <!--                          content="Dosyayı Sil"-->
              <!--                          placement="bottom">-->
              <!--                <el-button type="default" icon="mdi mdi-trash-can" circle size="mini"></el-button>-->
              <!--              </el-tooltip>-->
            </div>
          </el-upload>
        </el-col>
      </el-card>
    </el-col>
  </div>
</template>
<script>
import personelService from '../WSProvider/PersonelService';
import notification from '../notification';
import WsProvider from "@/WSProvider/provider/WSProvider";
import EventBus from "@/components/event-bus";


export default {
  name: "FileUploadCarousel",
  components: {},
  props: ["carouselList", "personelID", "asyncFileUploader", "makeParamForService", "downloadUrl"],
  mounted() {
    this.fileList = this.fileListWrapper(this.carouselList)
  },
  computed: {},
  data() {
    return {
      fileList: [],
      animate: true,
      removeID: null,
    }
  },
  watch: {
    carouselList(newList) {
      this.fileList = this.fileListWrapper(newList)
    },
    personelID(newId, oldId) {
      if (newId !== oldId) {
        this.animate = true
        setTimeout(() => {
          this.animate = false
        }, 2000);
      }
    }
  },
  methods: {
    fileListWrapper(list) {
      return list.map(x => {
        let param = {
          file: [],
          fromServer: false,
          isUploading: false,
          ...x,
        }
        if (x.file !== undefined && x.file.length > 0) {
          param.fromServer = true
          param.updateID = x.file[0].id
        }
        return param
      })
    },
    async handleUploadFile(index) {
      try {
        this.fileList[index].isUploading = true
        let res = await this.asyncFileUploader(this.makeParamForService(this.fileList[index]))
        if (res.status) {
          // EventBus.$emit("yenile")
          notification.Status('success', this, this.fileList[index].title + ' başarıyla eklendi.')
          this.fileList[index].fromServer = true
          this.fileList[index].isUploading = false
          this.fileList[index].file[0].id = res.data.id
          this.fileList[index].file[0].updateID = res.data.id
          this.fileList[index].file[0].dosyaYolu = res.data.dosyaYolu

          this.fileList[index].id = res.data.id
          this.fileList[index].updateID = res.data.id
          this.fileList[index].dosyaYolu = res.data.dosyaYolu
          // ! sayfa yenilenmeden önce,  2. ekledigi resmi sildikten sonra bug olusmamasi icin
          this.carouselList[index].file = [[]]
          this.carouselList[index].file[0].id = res.data.id
          this.carouselList[index].file[0].dosyaYolu = res.data.dosyaYolu
        } else {
          notification.Status('errorMsg', this, this.fileList[index].title + ' eklenemedi.')
          this.fileList[index].isUploading = false
          this.fileList[index].fromServer = false
          this.fileList[index].file = []
        }
      } catch (error) {
        console.log(error);
        this.fileList[index].isUploading = false
        this.fileList[index].fromServer = false
        notification.Status('errorMsg', this, this.fileList[index].title + ' eklenemedi.')
      }
    },
    fileRemove(index) {
      const {id} = this.fileList[index].file[0]
      this.removeID = id
      personelService.personelOzlukSil(id).then(response => {
        if (response.status === 200) {
          this.fileList[index].file = []
          this.fileList[index].fromServer = false
          this.fileList[index].isUploading = false
          this.fileList[index].updateID = undefined
          localStorage.setItem("userDataDemirag", response.token)
          notification.Status("success", this, response.msg)
        }
      })
          .catch(err => {
            if (err.responseJSON) {
              let error = err.responseJSON
              if (error.status == 401) {
                notification.Status(150, this);
              } else notification.Status("errorMsg", this, error.errMsg)
            }
          }).finally(() => {
        this.removeID = null
      })
    },
    handleDownloadFile(index) {
      window.open(personelService.ozlukPath + this.fileList[index].file[0].dosyaYolu, "_blank");
    },
    handleFileChange(file, index, fromServer, updateID) {
      file.raw["tmp_name"] = file.url;
      if (fromServer) this.fileList[index].updateID = updateID;
      this.fileList[index].file = [file.raw];
      this.fileList[index].fromServer = false;
    },
    handleRemove(index) {
      delete this.fileList[index].updateID
      if (this.carouselList[index].file) this.fileList[index].file = this.carouselList[index].file, this.fileList[index].fromServer = true
      else this.fileList[index].file = []
    },
    handlePreview(file) {
      console.log(file);
    },
  }
}

</script>

<style scoped lang="css">
.grid-view {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 14px;
  margin-top: 8px;
  padding: 8px;
}

.grid-item {
  padding: 0px !important;
  min-height: 130px;
}

.bq {
  margin: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
}

.el-upload-list__item-name {
  margin: 0;
}

.card-body-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.warning {
  border: 2px solid #ffa6a6;
  background-color: #ffe7e7;
  box-shadow: inset 0px 0px 9px 0px red;
}

.warning.warningAnimation {
  animation-name: emptyFileWarning;
  animation-iteration-count: infinite;
}

.el-upload__tip {
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 40px;
}

@media only screen and (width > 540px) and (width < 989px) {
  .grid-view {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (width < 540px) {
  .grid-view {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (width > 990px) and (width < 1360px) {
  .grid-view {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (width > 1400px) and (width < 1780px) {
  .grid-view {
    grid-template-columns: repeat(4, 1fr);
  }
}


@media only screen and (width > 1359px) and (width < 1420px) {
  .grid-view {
    grid-template-columns: repeat(3, 1fr);
  }
}

@keyframes emptyFileWarning {
  0% {
    box-shadow: inset 0px 0px 2px 0px red;
  }

  50% {
    box-shadow: inset 0px 0px 8px 0px red;
  }

  100% {
    box-shadow: inset 0px 0px 2px 0px red;
  }
}

.mini-action-icons {
  top: 0;
  position: absolute;
  right: 0;
}

.mini-action-icons button {
  border-radius: 1px;
  margin: 0;
}

.mini-action-icons .update-btn {
  transform: translateX(-30px);
}

.mini-action-icons button:nth-child(2) {
  color: red;
}

</style>
<style>
.el-tooltip__popper {
  padding: 4px !important;
  height: 20px !important;
}
</style>