<template>
  <div class="page-table musteriTanim mainDiv mb-30" id="affix-container">
    <div class="animated fadeInUp card-base baslikBg">
      Personel Özlük Tanımla
    </div>
    <el-row
      class="mt-10"
      :gutter="10"
      v-loading="loading || addLoading"
      :element-loading-text="
        loading
          ? 'Yükleniyor'
          : addLoading
          ? 'Veriler Ekleniyor. Lütfen Bekleyiniz.'
          : ''
      "
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 1)"
    >
      <el-form
        status-icon
        :model="ozlukForm"
        :rules="rulesOzluk"
        ref="ozlukForm"
        class="demo-ruleForm mobile-change"
      >
        <el-col :lg="24" :md="24" :sm="24" :xs="24">
          <div
            class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft pb-20 pt-20"
          >
            <el-row>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Personel" prop="personel" :key="count">
                  <el-select
                    style="padding-bottom: 0; width: 100%"
                    size="small"
                    v-model="ozlukForm.personelID"
                    placeholder="Personel Seçiniz"
                    filterable
                    :filter-methods="filterMethod"
                  >
                    <el-option
                      v-for="item in ozlukForm.personel"
                      :key="item.ID"
                      :label="`${item.sozlesmeKategoriAdi} ->  ${item.TC} -> ${item.adSoyad}`"
                      :value="item.ID"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :xl="19" :lg="17" :md="24" :sm="24" :xs="24" class="first">
          <FileUploadCarousel
            :carouselList="formFileList"
            :personelID="ozlukForm.personelID"
            :asyncFileUploader="asyncFileUploader"
            :makeParamForService="serviceParamWrapper"
          />
        </el-col>
        <el-col :xl="5" :lg="7" :md="24" :sm="24" :xs="24" class="mb-10 second">
          <el-row v-if="ozlukForm.personelID > 0">
            <el-col
              :lg="24"
              :md="24"
              :sm="24"
              :xs="24"
              class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft mt-8 pl-8"
            >
              <div class="bq">
                <i class="el-icon-info"></i>
                <code
                  >jpg/png/doc/xls/pdf formatında belge yükleyebilirsiniz.</code
                >
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col
              :lg="24"
              :md="24"
              :sm="24"
              :xs="24"
              style="margin-top: 10px"
              v-if="ozlukForm.personelID > 0"
            >
              <el-collapse
                class="yanForm animated fadeInUp vesikalik-area"
                v-model="activeCollapse"
              >
                <el-collapse-item
                  :class="{ warning: selectedImages.length === 0 }"
                  class="card-shadow--medium"
                  name="2"
                  :title="`2 Adet Vesikalık Fotoğraf (${selectedImages.length} adet eklendi)`"
                >
                  <el-button
                    v-if="selectedImages.length > 0"
                    :icon="'el-icon-upload'"
                    :loading="imageUploading"
                    size="mini"
                    type="info"
                    @click="saveImages"
                    class="styled-upload-button m-16 mb-0"
                    >Kaydet
                  </el-button>
                  <div class="pt-0">
                    <Resim
                      clearImageToSend="clearImageToSend"
                      functionName="onPersonalImage"
                      :imageList="selectedImages"
                      :selectedsFromServer="selectedImages"
                    >
                    </Resim>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-col>
          </el-row>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import draggable from "vuedraggable";

import EventBus from "@/components/event-bus";
import JQuery from "jquery";
import yoneticiService from "@/WSProvider/YoneticiService";
import personelService from "@/WSProvider/PersonelService";
import ImageCrop from "../../../components/ImageCrop.vue";
import FileUploadCarousel from "../../../components/FileUploadCarousel.vue";
import Resim from "../../../components/Resim.vue";
import notification from "../../../notification";

let $ = JQuery;
// dosyalar data içerisinde tanımlanmamalı. Vue state yapısı dosyaların referansını bozuyor

export default {
  name: "OzlukTanimla",
  components: {
    Resim,
    ImageCrop,
    draggable,
    FileUploadCarousel,
  },
  computed: {
    getPersonalID() {
      return this.ozlukForm.personelID;
    },
    storePersonelID() {
      return this.$store.getters.getPersonelOzlukID;
    },
  },
  watch: {
    storePersonelID(newWal) {
      this.ozlukForm.personelID = this.storePersonelID;
    },
    async getPersonalID() {
      try {
        this.loading = true;
        if (
          this.ozlukForm.personelID === undefined ||
          this.ozlukForm.personelID === null
        )
          return;
        let response = await personelService.getPersonalDetail({
          personelID: this.ozlukForm.personelID,
        });
        this.selectedImages =
          response.data.resimler.map((item) => {
            return {
              url: personelService.ozlukImagePath + item.resim,
              name: item.resim,
              id: item.id,
            };
          }) || [];
        this.formFileList =
          this.formFileListDefault.map((form) => {
            if (response.data.belgeList === undefined) return form;
            let file = response.data.belgeList.find(
              (x) => x.baslik === form.id
            );
            if (file) {
              return {
                ...form,
                file: [file],
              };
            } else {
              return form;
            }
          }) || [];
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },
  mounted() {
    let self = this;
    this.getManagerList();
    EventBus.$on("yenile", () => {
      const val = this.ozlukForm.personelID;
      this.ozlukForm.personelID = 0;
      setTimeout(() => {
        this.ozlukForm.personelID = val;
      }, 50);
    });
    EventBus.$on("onPersonalImage", function (bool, lastPushImage, allImage) {
      if (bool) {
        self.eklenen = lastPushImage;
        self.selectedImages = allImage;
      }
    });
    if (this.storePersonelID) {
      this.ozlukForm.personelID = this.storePersonelID;
    }
  },
  data() {
    return {
      selectedImages: [],
      eklenen: [],
      formFileList: [],
      formFileListDefault: [
        {
          title: "Nüfus Cüzdanı Fotokopisi",
          id: "nufusCuzdaniEvrak",
        },
        {
          title: "Nüfus Kayıt Örneği",
          id: "nufusKayitOrnegiEvrak",
        },
        {
          title: "İş Sözleşmesi / Hizmet Sözleşmesi",
          id: "isHizmetSozlesmesiEvrak",
        },
        {
          title: "İş Başvuru Formu",
          id: "isBasvuruFormuEvrak",
        },
        {
          title: "İkametgah Belgesi",
          id: "ikametgahBelgesiEvrak",
        },
        {
          title: "Sağlık Raporu",
          id: "saglikRaporuEvrak",
        },
        {
          title: "Diploma Fotokopisi",
          id: "diplomaFotokopisiEvrak",
        },
        {
          title: "Adli Sicil Kaydı",
          id: "adliSicilKaydiEvrak",
        },
        {
          title: "SGK İşe Giriş Bildirgesi",
          id: "sgkIseGirisBildirgesiEvrak",
        },
        {
          title: "Erkekler için Askerlik Durum Belgesi",
          id: "askerlikDurumBelgesiSecimiEvrak",
        },
      ],
      addLoading: false,
      loading: false,
      imageUploading: false,
      imageLoading: false,
      activeCollapse: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "12"],
      ozlukForm: {
        nufusCuzdaniEvrak: [],
        nufusKayitOrnegiEvrak: [],
        isHizmetSozlesmesiEvrak: [],
        isBasvuruFormuEvrak: [],
        ikametgahBelgesiEvrak: [],
        saglikRaporuEvrak: [],
        diplomaFotokopisiEvrak: [],
        adliSicilKaydiEvrak: [],
        sgkIseGirisBildirgesiEvrak: [],
        askerlikDurumBelgesiSecimiEvrak: [],
        images: [],
        evrakID: "",
        evrakAdi: "",
        evrakTarihi: "",
        kurumID: "",
        gelen: "",
        giden: "",
        kurumDurumu: "",
        durum: "1",
        personelID: "",
      },
      rulesOzluk: {},
      count: 0,
    };
  },
  methods: {
    async saveImages() {
      try {
        this.imageUploading = true;
        let res = await personelService.createPersonalInfoImage({
          personelID: this.ozlukForm.personelID,
          images: this.eklenen,
        });
        if (res.status) {
          try {
            this.loading = true;
            if (
              this.ozlukForm.personelID === undefined ||
              this.ozlukForm.personelID === null
            )
              return;
            let response = await personelService.getPersonalDetail({
              personelID: this.ozlukForm.personelID,
            });
            this.selectedImages =
              response.data.resimler.map((item) => {
                return {
                  url: personelService.imagePath + item.resim,
                  name: item.resim,
                };
              }) || [];
            this.formFileList =
              this.formFileListDefault.map((form) => {
                if (response.data.belgeList === undefined) return form;
                let file = response.data.belgeList.find(
                  (x) => x.baslik === form.id
                );
                if (file) {
                  return {
                    ...form,
                    file: [file],
                  };
                } else {
                  return form;
                }
              }) || [];
            EventBus.$emit("yenile");
            this.loading = false;
          } catch (error) {
            this.loading = false;
            console.log(error);
          }
          notification.Status(
            "success",
            this,
            "Resim(ler) başarıyla kayıt edildi."
          );
          this.imageUploading = false;
        } else {
          notification.Status("errorMsg", this, "Resim(ler) kayıt edilemedi.");
          this.imageUploading = false;
        }
      } catch (error) {
        console.log(error);
        this.imageUploading = false;
        notification.Status("errorMsg", this, "Resim(ler) kayıt edilemedi.");
      }
    },
    serviceParamWrapper(item) {
      return {
        personelID: this.ozlukForm.personelID,
        belge: item.file[0],
        baslik: item.id || item.file[0].id,
        updateID: item.updateID || item.file[0].updateID,
      };
    },
    asyncFileUploader: personelService.createPersonalInfoFiles,
    handleDownloadFile(index) {
      console.log("download", this.formFileList[index].file);
    },
    handleFileChange(file, index) {
      file.raw["tmp_name"] = file.url;
      this.formFileList[index].file = [file.raw];
      this.formFileList[index].fromServer = false;
    },
    handleRemove(index) {
      this.formFileList[index].file = [];
    },
    handlePreview(file) {
      console.log(file);
    },
    getManagerList() {
      try {
        this.loading = true;
        personelService
          .personelList(1, 0, 10000)
          .then((response) => {
            if (response.status == 200) {
              localStorage.setItem("userDataDemirag", response.token);
              this.ozlukForm.personel = response.data;
              this.count++;
            }
            this.loading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.ozlukForm.personel = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            }
            this.loading = false;
          });
      } catch (e) {
        this.loading = false;
      }
    },
    evrakTanimla(formName) {
      if (this.validateForm(formName)) {
        this.openConfirmDialog("Evrak eklemek istediğinize emin misiniz?").then(
          () => {
            try {
              this.addLoading = true;
              personelService
                .createPersonalInfoFiles({
                  files: fileListData,
                  images: this.selectedImages,
                  personelID: this.ozlukForm.personelID,
                })
                .then((response) => {
                  if (response.status == 200) {
                    localStorage.setItem("userDataDemirag", response.token);
                    notification.Status("success", this, response.msg);
                    this.resetForm(formName);
                  }
                  this.addLoading = false;
                })
                .catch((err) => {
                  if (err.responseJSON) {
                    let error = err.responseJSON;
                    if (error.status == 401) {
                      notification.Status(150, this);
                    } else {
                      if (error.status == 400) {
                        notification.Status("warning", this, error.errMsg);
                      }
                      if (error.status == 503) {
                        notification.Status("warning", this, error.msg);
                      }
                    }
                  } else {
                    //notification.Status(503, this)
                  }
                  this.addLoading = false;
                });
            } catch (e) {
              console.log(e);
              this.addLoading = false;
            }
          }
        );
      }
    },
    filterMethod(query, item) {
      return item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.ozlukForm.personelID = "";
      this.ozlukForm.nufusCuzdaniEvrak = [];
      this.ozlukForm.nufusKayitOrnegiEvrak = [];
      this.ozlukForm.isHizmetSozlesmesiEvrak = [];
      this.ozlukForm.isBasvuruFormuEvrak = [];
      this.ozlukForm.ikametgahBelgesiEvrak = [];
      this.ozlukForm.saglikRaporuEvrak = [];
      this.ozlukForm.diplomaFotokopisiEvrak = [];
      this.ozlukForm.adliSicilKaydiEvrak = [];
      this.ozlukForm.sgkIseGirisBildirgesiEvrak = [];
      this.ozlukForm.askerlikDurumBelgesiSecimiEvrak = [];
      this.getManagerList();

      $("#nufusCuzdaniEvrakEkle .el-upload--belge-card").show();
      $("#nufusKayitOrnegiEvrakEkle .el-upload--belge-card").show();
      $("#isHizmetSozlesmesiEvrakEkle .el-upload--belge-card").show();
      $("#isBasvuruFormuEvrakEkle .el-upload--belge-card").show();
      $("#ikametgahBelgesiEvrakEkle .el-upload--belge-card").show();
      $("#saglikRaporuEvrakEkle .el-upload--belge-card").show();
      $("#diplomaFotokopisiEvrakEkle .el-upload--belge-card").show();
      $("#adliSicilKaydiEvrakEkle .el-upload--belge-card").show();
      $("#sgkIseGirisBildirgesiEvrakEkle .el-upload--belge-card").show();
      $("#askerlikDurumBelgesiSecimiEvrak .el-upload--belge-card").show();
    },
    nufusCuzdaniEvrakRemove() {
      $("#nufusCuzdaniEvrakEkle .el-upload--belge-card").show();
      this.nufusCuzdaniEvrakListMain = [];
      this.ozlukForm.nufusCuzdaniEvrak = [];
    },
  },
};
</script>
<style scoped lang="css">
.bq {
  margin: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
}

.el-upload-list__item-name {
  margin: 0;
}

.card-body-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.warning {
  border: 2px solid #ffa6a6;
  background-color: #ffe7e7;
  box-shadow: inset 0px 0px 9px 0px red;
  animation-name: emptyFileWarning;
  animation-iteration-count: 3;
}

.el-upload__tip {
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 40px;
}

@media screen and (max-width: 1200px) {
  .mobile-change {
    display: flex !important;
    flex-flow: column !important;
  }

  .first {
    order: 2 !important;
  }

  .second {
    order: 1 !important;
  }
}

@media only screen and (width > 1200px) and (width < 1446px) {
  .vesikalik-area {
    width: 104% !important;
  }
}
</style>
