<template>
    <el-dialog :visible="dialogVisible" @close="$emit('update:dialogVisible', false)" width="60%" class="dialogComponents">
        <span slot="title">
            <div style="font-size: 20px !important">RESİM KIRPMA</div>
        </span>

        <div class="display-center m-15" >
            <croppa :placeholder="'Resim Seçiniz.'" id="croppa" :placeholder-font-size="14" :canvas-color="'default'" v-model="myCroppa" :width="200" :heigth="200" ref="myCroppa" :zoom-speed="15">
            </croppa>
        </div>
        
        <button @click.prevent="generateImage">Generate</button>
        <br>

        <div class="display-center">
            <img class="output" :src="imgUrl" >
        </div>

        <el-button @click="downloadImage">Resmi Kaydet</el-button>
    </el-dialog>
</template>


<script>
import Croppa from 'vue-croppa';

export default {
    props: ["dialogVisible"],
    components: {
        croppa: Croppa.component
    },
    data(){
        return {
            myCroppa: null,
            imgUrl: '',
            imgName: '',
        }
    },
    mounted(){
        this.myCroppa = null
        this.imgUrl = ''

        var croppaInput = document.querySelector("#croppa input")
        croppaInput.addEventListener("change", function(event){
           // console.log({event})
        })
    },
    computed:{
        url(){
            return this.myCroppa.generateDataUrl()
        }
    },
    watch: {
        url(val){
           // console.log({val})
        }
    },
    methods: {
        generateImage() {
            let url = this.myCroppa.generateDataUrl()
            if (!url) {
                alert('Resim Seçilmemiş!')
                return
            }
            this.imgUrl = url
        },

        downloadImage(){
            var downloadLink = document.createElement("a");
            downloadLink.href = this.imgUrl;
            downloadLink.download = this.imgName;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            this.$emit('update:dialogVisible', false)
        },

    },
}
</script>


<style>
    .croppa-container {
        background-color: rgb(241, 241, 241);
        height: 200px;
        width: 200px;
        border: 1px dashed black;
    }
</style>